<template>
  <div>
    <b-row class="justify-content-center">
      <b-col
        sm="12"
        lg="6"
        class="d-flex justify-content-center"
        style="margin-bottom:-15px; padding:0px;"
      >
        <!-- apex chart -->
        <vue-apex-charts
          type="radialBar"
          height="300"
          :options="radialChartOptionsComputed"
          :series="data.radial.series"
        />
        <div
          class="change-indicator-large"
          :style="{color:data.radial.colorB}"
        >
          <feather-icon
            :icon="data.radial.change.icon"
            size="44"
          />
        </div>
      </b-col>
      <b-col
        sm="12"
        lg="6"
      >
        <div style="padding:20px;">
          <h4>{{ data.text.title }}</h4>
          <div v-html-safe="data.text.body" />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div style="padding:20px;">
          <H4>"{{ data.name }} Score is {{ data.radial.series[0]/10 }}"</H4>
          <p v-if="data.radial.series[0] < 3">
            Your {{ data.name }} score is <b>Very low</b>; There is much room for improvement. {{ data.text.p1 }} <b>{{ data.text.percentage }}%</b> {{ data.text.p2 }}
          </p>
          <p v-else-if="data.radial.series[0] < 5">
            Your {{ data.name }} score is <b>Rather low</b>; There is much room for improvement. {{ data.text.p1 }} <b>{{ data.text.percentage }}%</b> {{ data.text.p2 }}
          </p>
          <p v-else-if="data.radial.series[0] < 7">
            Your {{ data.name }} score is <b>Average</b>; There is some further room for improvement. {{ data.text.p1 }} <b>{{ data.text.percentage }}%</b> {{ data.text.p2 }}
          </p>
          <p v-else-if="data.radial.series[0] < 9">
            Your {{ data.name }} score is <b>Good</b>; There is still further room for improvement. {{ data.text.p1 }} <b>{{ data.text.percentage }}%</b> {{ data.text.p2 }}
          </p>
          <p v-else>
            Your {{ data.name }} score is <b>Excellent</b>; There always further room for improvement. {{ data.text.p1 }} <b>{{ data.text.percentage }}%</b> {{ data.text.p2 }}
          </p>
          <p v-if="!data.isLowest">
            However, your <b>{{ data.lowestScores }}</b> scores are lowest, and may be the best place to focus first. Head over to the <b-link href="/actions">
              Actions Page
            </b-link> to see how to increase <b>{{ data.lowestScores }}</b>
          </p>

          <p v-if="data.isLowest">Head over to the <b-link href="/actions">
            Actions Page
          </b-link> to see how to increase {{ data.name }}.
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          responsive="sm"
          :items="data.items"
        />
      </b-col>
    </b-row>
    <div
      v-if="Object.keys(data.heatmap).length"
    >
      <b-row>
        <b-col style="padding:20px 20px 0px 20px;">
          <h4>{{ data.name }} in detail</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="padding:0px 20px 0px 20px;">
          <b-dropdown
            :text="data.activeBreakdownName"
            class="m-md-2"
          >
            <b-dropdown-item
              v-for="(item, key) in data.breakdownList"
              :key="key"
              @click="data.activeBreakdownName = item.value"
            >
              {{ item.text }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="padding:20px 20px 0px 20px;">
          <div id="chart">
            <vue-apex-charts
              type="heatmap"
              :options="getGridChartOptions"
              :series="getGridChartData"
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <div
      v-if="Object.keys(data.onaGraphs).length"
    >
      <b-row>
        <b-col style="padding:20px 20px 0px 20px;">
          <h4>{{ data.name }} Organisational Network Analysis</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="padding:0px 20px 0px 20px;">
          <b-dropdown
            :text="data.activeOnaGroup"
            class="m-md-2"
          >
            <b-dropdown-item
              v-for="(item, key) in data.onaGroups"
              :key="key"
              @click="data.activeOnaGroup = item.value"
            >
              {{ item.text }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="padding:0px 20px 0px 20px;">
          <d3-network
            :net-nodes="getNodes"
            :net-links="getLinks"
            :options="options"
          />
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import {
  BRow, BCol, BLink, BTable, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import D3Network from 'vue-d3-network'
import radialChartOptions from '../analytics/chartOptions'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BTable,
    D3Network,
    BDropdown,
    BDropdownItem,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    options() {
      console.log(this.data)
      return {
        force: 3000,
        size: { w: 500, h: 500 },
        nodeSize: 17,
        nodeLabels: false,
        canvas: false,
        linkWidth: 4,
      }
    },
    getNodes() {
      return (Object.keys(this.data.onaGraphs).length) ? this.data.onaGraphs[this.data.activeOnaGroup].nodes : {}
    },
    getLinks() {
      return (Object.keys(this.data.onaGraphs).length) ? this.data.onaGraphs[this.data.activeOnaGroup].links : {}
    },
    radialChartOptionsComputed() {
      const options = JSON.parse(JSON.stringify(radialChartOptions))
      options.colors = [this.data.radial.colorB]
      options.fill.gradient.gradientToColors = [this.data.radial.colorA]
      options.plotOptions.radialBar.track.background = [this.data.radial.colorBG]

      /* eslint func-names: ["error", "never"] */
      options.plotOptions.radialBar.dataLabels.value.formatter = function (val) {
        return Math.round(val * 100) / 1000
      }
      options.plotOptions.radialBar.dataLabels.value.fontSize = '5rem'
      options.plotOptions.radialBar.dataLabels.value.offsetY = 10
      return options
    },
    getGridChartData() {
      return (Object.keys(this.data.heatmap).length) ? this.data.heatmap[this.data.activeBreakdownName].rows : {}
    },
    getGridChartOptions() {
      const groupNames = typeof this.data.heatmap[this.data.activeBreakdownName] !== 'undefined' ? this.data.heatmap[this.data.activeBreakdownName].groupNames : []
      return {
        chart: {
          height: 'auto',
          type: 'heatmap',
        },
        plotOptions: {
          heatmap: {
            enableShades: false,
            radius: 8,
            colorScale: {
              ranges: [{
                from: 0,
                to: 0,
                name: 'No Data',
                color: '#eeeeee',
              }, {
                from: 0.1,
                to: 3.7,
                name: 'low',
                color: '#FF6E6E',
              },
              {
                from: 3.8,
                to: 6.2,
                name: 'neutral',
                color: '#FCBF1C',
              },
              {
                from: 6.3,
                to: 10,
                name: 'high',
                color: '#6FCF97',
              },
              ],
              min: 0.1,
              max: 10,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 5,
        },
        xaxis: {
          type: 'group',
          categories: groupNames,
          labels: {
            rotate: -45,
            rotateAlways: true,

          },
          position: 'bottom',

        },
        yaxis: {
          labels: {
            rotate: -45,
            rotateAlways: true,
          },
        },
      }
    },
  },
}
</script>
<style src="vue-d3-network/dist/vue-d3-network.css"></style>
<style>
  .node {
    stroke: rgba(0, 0, 0,.6);
    stroke-width: 3px;
    fill: #fff;
  }
  .node:hover {
      stroke: rgba(0, 0, 0,.6);
      stroke-width: 5px;
  }
  .node.pinned {
    stroke: rgba(0, 0, 0,.6);
  }

  .link:hover {
      stroke: rgba(252, 191, 28,.15);
      stroke-width: 5px;
  }
  .link {
      stroke: rgba(252, 191, 28,.15);
  }
</style>
